import { changeLog } from "./ChangeLog";

export const VERSION = changeLog[0].Version;
export const API_VERSION = '1.0.14.178';
export const ENVIRONMENT = {
	DEV: 'DEV',
	PROD: 'PROD'
};

export const ENDPOINTS = {
	// local dev
	//API: 'http://localhost:5009/api',
	//PROPOSAL_BASE_URL: 'http://localhost:3001/new-proposal/',
	//PROPOSAL_BASE_URL_V2: 'http://localhost:3001/proposal-v2/',


	// azure dev
	//API: 'https://sierra-live-api-dev.azurewebsites.net/api',
	//ENVIRONMENT: ENVIRONMENT.DEV,
	//PROPOSAL_BASE_URL: 'https://devproposal.sierra.live/new-proposal/',
	//PROPOSAL_BASE_URL_V2: 'https://devproposal.sierra.live/proposal-v2/',
	//SALESFORCE_BASE_URL: 'https://vda--fullcopy.sandbox.lightning.force.com/lightning/r/'



	// azure prod
	API: 'https://sierra-live-api-prod.azurewebsites.net/api',
	ENVIRONMENT: ENVIRONMENT.PROD,
	PROPOSAL_BASE_URL: 'https://proposal.sierra.live/new-proposal/',
	PROPOSAL_BASE_URL_V2: 'https://proposal.sierra.live/proposal-v2/',
	SALESFORCE_BASE_URL: 'https://vda.lightning.force.com/lightning/r/'
};


export const API_METHOD = {
	POST: 'POST',
	GET: 'GET',
	DELETE: 'DELETE'
};

export const LOCAL_STORAGE = {
	PROFILE: 'live-profile',
	TOKEN: 'live-token'
};

export const SESSION_STORAGE = {
	CUSTOMER_ID: 'customer-id',
	BUILDING_ID: 'building-id',
	INSPECTION_ID: 'inspection-id',
	INSPECTION_NAV_BACK: 'inspection-nav-back',
	INSPECTION_LINE_ID: 'inspection-line_id',
	AGENT_ID: 'agent-id',
	DEVICE_ID: 'device-id',
	ELEVATOR_ID: 'elevator-id',
	PROPOSAL_BATCH_ID: 'proposal-batch-id',
	PROPOSAL_PROPOSAL_ID: 'proposal-id',
	PROPOSAL_NAV_BACK_DASHBOARD: 'proposal-nav-back',
	OFFICE_ID: 'office-id',
	TOKEN: 'live-token',
	INVOICE_BATCH: 'invoice-batch',
	USER_ID: 'user-id',
	USER_NAV_BACK: 'user-nav-back',
};

export const STATUS = {
	ACTIVE: { ID: 1, VALUE: 'Active' },
	INACTIVE: { ID: 0, VALUE: 'Inactive' },
};

export const LOADER_PROPS = {
	LOADING: { show: true, title: 'loading, please wait....', message: "hang tight! we're getting your data", showSpinner: true, background: 'bg-info-lighten' },
	WARNING: { show: true, title: 'no data', message: 'the data set is empty', showSpinner: false, icon: 'uil-annoyed-alt', background: 'bg-warning-lighten' },
	DANGER: { show: true, title: 'select inspector', message: 'select an inspector to load weekly inspections', showSpinner: false, icon: 'uil-constructor', background: 'bg-danger-lighten' },
};

export const APP_BACKGROUNDS = {
	SUCCESS: 'bg-success-lighten',
	INFO: 'bg-info-lighten',
	PRIMARY: 'bg-primary-lighten',
	WARNING: 'bg-warning-lighten',
	DANGER: 'bg-danger-lighten',
};

export const APP_YEAR = {
	COUNT_DOWN_DATE: '2024-12-31',
	CURRENT_YEAR: '2024'
}

export const COPYRIGHT = APP_YEAR.CURRENT_YEAR + ' © Sierra Consulting Group.';

export const YEARS = [
	{ value: '2024', label: '2024' },
	{ value: '2023', label: '2023' },
	{ value: '2022', label: '2022' },
	{ value: '2021', label: '2021' },
	{ value: '2020', label: '2020' },
	{ value: '2019', label: '2019' },
	{ value: '2018', label: '2018' },
	{ value: '2017', label: '2017' },
	{ value: '2016', label: '2016' },
	{ value: '2015', label: '2015' },
	{ value: '2014', label: '2014' },
	{ value: '2013', label: '2013' },
	{ value: '2012', label: '2012' },
	{ value: '2011', label: '2011' },
	{ value: '2010', label: '2010' },
];


export const INVOICE_DELIVERY_METHODS = [
	{ value: 'Email', label: 'Email' },
	{ value: 'Mail', label: 'Mail' },
	{ value: 'Portal', label: 'Portal' }
];

export const YESNO_OPTIONS_LIST = [
	{ value: 0, label: 'No' },
	{ value: 1, label: 'Yes' }
];

export const YESNONA_OPTIONS_LIST = [
	{ value: 0, label: 'No' },
	{ value: 1, label: 'Yes' },
	{ value: 2, label: 'N/A' }
];



export const YESNO_OPTIONS = {
	NO: { ID: 0, VALUE: 'No' },
	YES: { ID: 1, VALUE: 'Yes' },
	NA: { ID: 2, VALUE: 'N/A' }
};


export const INSP_TYPES = {
	CAT1: { ID: 1, VALUE: 'Cat 1' },
	CAT1CAT5: { ID: 2, VALUE: 'Cat 1 & Cat 5' },
	CAT5: { ID: 3, VALUE: 'Cat 5' },
	PERIODIC: { ID: 7, VALUE: 'Periodic' }
};


// array for insp details line edit and adding insp line to inspection
//------------------------------------
export const INSP_TYPES_CAT = [
	{ value: 1, label: 'Cat 1' },
	{ value: 2, label: 'Cat 1 & Cat 5' },
	{ value: 3, label: 'Cat 5' }
];
export const INSP_TYPES_PERIODIC = [
	{ value: 7, label: 'Periodic' }
];
//------------------------------------

export const INSP_INVOICE_STATUS = {
	NOT_INVOICED: { ID: 0, VALUE: 'Not Invoiced' },
	IN_PROGRESS: { ID: 1, VALUE: 'Invoice In Progress' },
	PENDING: { ID: 2, VALUE: 'Invoice Pending' },
	COMPLETE: { ID: 3, VALUE: 'Invoice Complete' }
};

export const INSP_CAT1_RESULTS = {
	NA: { ID: 0, VALUE: 'N/A' },
	SATISFACTORY: { ID: 1, VALUE: 'Satisfactory' },
	UNSATISFACTORY: { ID: 2, VALUE: 'Unsatisfactory' }
};

export const INSP_CAT5_RESULTS = {
	NA: { ID: 0, VALUE: 'N/A' },
	SATISFACTORY: { ID: 1, VALUE: 'Satisfactory' }
};

export const INSP_MOBILE_STATUS = {
	NOT_ASSIGNED: { ID: 'Not Assigned', VALUE: 'Not Assigned' },
	TODO: { ID: 'ToDo', VALUE: 'To Do' },
	COMPLETE: { ID: 'Complete', VALUE: 'Complete' }
};

export const INSP_STATUS_PROGRESS = [
	{ ID: '0', PERCENT: 0 },
	{ ID: '1', PERCENT: 0 },
	{ ID: '2', PERCENT: 12 },
	{ ID: '3', PERCENT: 25 },
	{ ID: '4', PERCENT: 37 },
	{ ID: '5', PERCENT: 50 },
	{ ID: '6', PERCENT: 62 },
	{ ID: '7', PERCENT: 81 },
	{ ID: '8', PERCENT: 100 },
	{ ID: '10', PERCENT: 0 }
];

export const PROPOSAL_STATUSES = {
	DRAFT: { ID: 0, VALUE: 'Draft' },
	SENT: { ID: 1, VALUE: 'Sent' },
	VIEWED: { ID: 2, VALUE: 'Viewed' },
	SUBMITTED: { ID: 3, VALUE: 'Submitted' },
	MODIFIED: { ID: 8, VALUE: 'Modified' } // we updated the proposal - either the price or something else - deleted a line from the prop, updated the price, etc.
};

export const CUSTOMER_ANALYTICS_TITLE = {
	INSPECTION_SUMMARY: { TITLE: 'Inspection Status Summary' },
	ELEVATOR_CATEGORY_RESULTS: { TITLE: 'Elevator Inspection Cat 1 & Periodic Results' }
};

export const INSP_CANCEL_REASONS = [
	{ value: 'Other Third Party', label: 'Other Third Party' },
	{ value: 'Price', label: 'Price' },
	{ value: 'New Management Company', label: 'New Management Company' },
	{ value: 'Duplicate', label: 'Duplicate' },
	{ value: 'Other', label: 'Other' }
];

export const INSP_QCE_STATUSES = {
	QCE_NO: { ID: 0, VALUE: 'QCE Not Needed' }, // default - 
	AWAITING_COMPLETE: { ID: 1, VALUE: 'QCE Awaiting Insp Line Complete' }, // auto // line is complete - if YES in header and line is not complete
	AWAITING_FIELD_REPORT: { ID: 2, VALUE: 'QCE Awaiting Field Report' }, // auto must be complete and yes in header
	RECEIVED_FIELD_REPORT: { ID: 3, VALUE: 'QCE Received Field Report' }, // manual
	REPORT_IN_PROGRESS: { ID: 4, VALUE: 'QCE Report In Progress' }, // manual
	SENT_TO_CLIENT: { ID: 5, VALUE: 'QCE Sent To Client' } // manual
};

// used for dropdown options
export const INSP_QCE_STATUSES_LIST = [
	//{ value: 0, label: 'QCE Not Needed' },
	//{ value: 1, label: 'QCE Awaiting Insp Line Complete' },
	{ value: 2, label: 'QCE Awaiting Field Report' },
	{ value: 3, label: 'QCE Received Field Report' },
	{ value: 4, label: 'QCE Report In Progress' },
	{ value: 5, label: 'QCE Sent To Client' }
];

export const INSP_STATUSES = {
	NEW: { ID: 1, VALUE: 'New' },
	PROPOSAL_SENT: { ID: 2, VALUE: 'Proposal Sent' },
	TO_BE_SCHEDULED: { ID: 3, VALUE: 'To Be Scheduled' },
	PARTIALLY_SCHEDULED: { ID: 4, VALUE: 'Partially Scheduled' },
	IN_PROGRESS: { ID: 5, VALUE: 'In Progress' },
	INSPECTION_COMPLETE: { ID: 6, VALUE: 'Inspection(s) Complete' },
	TRACK_AOC: { ID: 7, VALUE: 'Track AOC' },
	JOB_COMPLETE: { ID: 8, VALUE: 'Job Complete' },
	CANCELED: { ID: 10, VALUE: 'Canceled' }
};

export const INSP_STATUSES_LIST = [
	{ ID: 1, VALUE: 'New', COLOR: '#2c8ef8' },
	{ ID: 2, VALUE: 'Proposal Sent', COLOR: '#727cf5' },
	{ ID: 3, VALUE: 'To Be Scheduled', COLOR: '#6b5eae' },
	{ ID: 4, VALUE: 'Partially Scheduled', COLOR: '#ff679b' },
	{ ID: 5, VALUE: 'In Progress', COLOR: '#fa5c7c' },
	{ ID: 6, VALUE: 'Inspection(s) Complete', COLOR: '#fd7e14' },
	{ ID: 7, VALUE: 'Track AOC', COLOR: '#ffbc00' },
	{ ID: 8, VALUE: 'Job Complete', COLOR: '#0acf97' },
	{ ID: 10, VALUE: 'Job Canceled', COLOR: '#02a8b5' }
];

// used for the charts - refactor this is not good
// export const INSP_STATUSES_LIST2 = [
// 	{ name: 'New' },
// 	{ name: 'Proposal Sent' },
// 	{ name: 'To Be Scheduled' },
// 	{ name: 'Partially Scheduled' },
// 	{ name: 'In Progress' },
// 	{ name: 'Inspection(s) Complete' },
// 	{ name: 'Track AOC' },
// 	{ name: 'Job Complete' },
// 	{ name: 'Job Canceled' }
// ];





export const INVOICE_BATCH_STATUSES = {
	ACTIVE: { ID: 0, VALUE: 'Active' }, // used to gather invoices if there is already a batch in progress, new invoices will go here
	IN_PROGRESS: { ID: 1, VALUE: 'In Progress' }, // has invoices that have started to be pushed
	COMPLETED: { ID: 2, VALUE: 'Completed' }, // closed batch
};

// -------------------------------------------
// used to build links into salesforce
export const SALESFORCE_ENTITY_TYPE = {
	CUSTOMER: 'Account',
	BUILDING: 'Location',
	DEVICE: 'Device',
	INVOICE: 'AcctSeed__Billing__c'
};

export const SIERRA_ENTITY_TYPE = {
	CUSTOMER: 'Customer',
	BUILDING: 'Building',
	DEVICE: 'Device',
	INVOICE: 'Invoice',
};
// -------------------------------------------